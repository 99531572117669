module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-05460f907b/3/buildhome/.yarn_cache/gatsby-remark-images-npm-7.2.0-6c6e1c4623-bd59bbb95d.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"quality":100,"backgroundColor":"#191B1F","linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-canonical-urls-virtual-6f98c78b25/3/buildhome/.yarn_cache/gatsby-plugin-canonical-urls-npm-5.2.0-804deb6322-902262bfe4.zip/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://focustimetracker.app"},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-647ff3bc72/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
